<template>
  <default-layout class="bg-background">
    <div class="bg-background p-4 content-element">
      <div
        class="mb-4 p-4 pb-1 rounded-xl border border-gray-300 border-solid bg-white text-left"
      >
        <div class="mb-2 text-xl font-bold">Thông tin khoản ứng</div>
        <div class="border-b pb-2">
          <div class="flex justify-between">
            <div>Ngày ứng:</div>
            <div class="font-medium">
              {{ formatTime(createdAt) }}
            </div>
          </div>
        </div>
        <div class="pt-2">
          <div class="flex justify-between mb-2">
            <div>Số tiền yêu cầu:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(amount) }} VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Kỳ hạn:</div>
            <div class="text-primary font-medium">{{ term }} tháng</div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Phí xử lý:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(paidMoney) }} VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2 border-t pt-2">
            <div>Tiền thực nhận:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(realMoney) }}
              VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Tiền trả hàng tháng (dự kiến):</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(monthlyPayment) }}
              VNĐ
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useRouter } from 'vue-router';
import { calFee, calMonthlyPayment, numberToMoney } from '@/helpers/currency';
import constRouter from '@/constants/constRouter';
import { list } from '@/apis/ApiBorrower';
import { FEE1, FEE2, INTEREST } from '@/constants';

export default defineComponent({
  components: {
    DefaultLayout,
  },
  name: 'SalaryAdvanceItemDetail',
  setup() {
    const router = useRouter();
    const toFacebook = () => {
      window.open('https://m.me/440208153217175', '_blank');
      // this.$router.push({ name: HOME });
    };
    const wayBackHome = () => {
      router.push({
        name: constRouter.DASHBOARD.name,
      });
    };

    return {
      toFacebook,
      wayBackHome,
      numberToMoney,
    };
  },
  data: () => ({
    requestInfo: {},
    createdAt: 0,
    amount: 0,
    term: 0,
    paidMoney: 0,
    realMoney: 0,
    monthlyPayment: 0 as any,
  }),
  created() {
    list({ page: 1, limit: 20 }).then(({ data }) => {
      if (data.data?.length) {
        const list = data.data;
        const id = this.$route.params.id;
        const theItem = list.find((item: any) => item.id === id);
        this.amount = theItem.amount;
        this.term = theItem.term;
        this.monthlyPayment = numberToMoney(
          calMonthlyPayment(theItem.amount, INTEREST, theItem.term),
        );
        if (theItem.term <= 1) {
          this.paidMoney = calFee(theItem.amount, FEE1);
        } else {
          this.paidMoney = calFee(theItem.amount, FEE2);
        }

        this.realMoney = Math.max(this.amount - this.paidMoney, 0);
      }
    });
  },
  methods: {
    formatTime(time: string) {
      if (time) {
        const text = time.substr(0, 10); // 2021-01-18
        const array = text.split('-');
        return `${array[2]}/${array[1]}/${array[0]}`; // 18/01/2021
      }
      return null;
    },
  },
});
</script>
